import React from "react";
import styled from "@emotion/styled";

const Button = styled.button`
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  width: 100%;
  border: none;
  &:hover,
  &:focus,
  &:disabled,
  &:active,
  &:visited {
    border: 0;
    outline: none;
  }
`;

const ButtonDiv = styled(Button)`
  box-shadow: ${props =>
    props.disableStyle ? "none" : "0 9px 18px 0 rgba(253, 165, 54, 0.3)"};
  background-color: ${props =>
    props.disableStyle
      ? props.disabledBackGroundColor
        ? props.disabledBackGroundColor
        : "#f2f2f3"
      : "#ff9c1b"};
  color: ${props =>
    props.disableStyle && !props.disabledBackGroundColor
      ? "rgba(119, 121, 132, 0.5)"
      : "#ffffff"};
  &:hover {
    box-shadow: ${props =>
      props.disableStyle ? "none" : "0 9px 18px 0 rgba(253, 165, 54, 0.3)"};
    background-color: ${props =>
      props.disableStyle
        ? props.disabledBackGroundColor
          ? props.disabledBackGroundColor
          : "#f2f2f3"
        : "#f4961b"};
  }
`;

const SearchSpinner = styled.img`
  width: 20px;
  height: 20px;
`;

const SpinnerButton = styled(Button)`
  background-color: #777984 !important;
`;

const SubmitButton = ({
  children,
  isLoading,
  styles,
  action,
  disabled,
  appearDisabled,
  disabledBackGroundColor
}) => {
  return isLoading ? (
    <SpinnerButton style={styles}>
      <SearchSpinner
        src={"https://cdn.cardcash.com/home/images/other/spinner.gif"}
        alt=""
      />
    </SpinnerButton>
  ) : (
    <ButtonDiv
      disabled={disabled}
      disableStyle={disabled || appearDisabled}
      disabledBackGroundColor={disabledBackGroundColor}
      onClick={e => {
        if (action) action(e);
      }}
      style={{
        ...styles,
        cursor: disabled || appearDisabled ? "not-allowed" : "pointer"
      }}
    >
      {children}
    </ButtonDiv>
  );
};

export default SubmitButton;
