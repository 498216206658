import { useState, useEffect } from "react";
import { valuesFulfilled } from "@utils/validators/form";

const useFormHook = (
  callback,
  initialState,
  validator,
  formName,
  inputRules,
  initialValidation = true
) => {
  const [inputs, setInputs] = useState(initialState);
  const keys = Object.keys(initialState);
  const theObj = {};
  keys.forEach(key => {
    const obj = { blurred: false, touched: false };
    theObj[key] = obj;
  });
  const [meta, setMeta] = useState(theObj);
  const [focusElementName, changeFocusElementName] = useState("");
  const [blurElementName, changeBlurElementName] = useState("");
  const [formErrors, setError] = useState({});
  const [disabled, changeDisabled] = useState(initialValidation);
  const [unfullfilledValues, setUnfullfilledValues] = useState([]);

  useEffect(() => {
    const validateErrors = {};
    let thereIsAError = false;
    if (validator) {
      for (const input in inputs) {
        const inputObject = {
          name: input,
          value:
            typeof inputs[input] === "string"
              ? inputs[input].trim()
              : inputs[input]
        };
        const validState = validator({
          inputObject,
          formName,
          inputRules,
          inputs
        });
        if (validState.errorMessage) {
          thereIsAError = true;
          if (inputs[input]) {
            validateErrors[input] = validState;
          }
        }
      }
      setError(validateErrors);
      if (thereIsAError) {
        changeDisabled(true);
      } else {
        changeDisabled(false);
      }
    }
  }, []);

  const handleSubmit = event => {
    setMeta({ ...meta, submitAttempted: true });
    changeDisabled(true);
    if (event) {
      event.preventDefault();
    }
    if (validator) {
      const validateErrors = {};
      for (const input in inputs) {
        const inputObject = {
          name: input,
          value:
            typeof inputs[input] === "string"
              ? inputs[input].trim()
              : inputs[input]
        };
        const validState = validator({
          inputObject,
          formName,
          inputRules,
          inputs
        });
        if (validState.errorMessage) {
          validateErrors[input] = validState;
        } else {
          if (event?.target) {
            const newErrors = formErrors;
            delete newErrors[event.target.name];
            setError({ ...newErrors });
          }
        }
      }

      setError(validateErrors);
      if (Object.entries(validateErrors).length === 0) {
        callback(inputs, event);
      }
    } else {
      callback(inputs, event);
    }
  };

  const forceSubmit = event => {
    if (event) {
      event.preventDefault();
    }
    callback(inputs, event);
  };

  const resetFields = (name, value) => {
    const updatedObject = {
      ...inputs,
      [name]: typeof value === "string" ? value.trim() : value
    };

    const inputObject = {
      name: name,
      value: typeof value === "string" ? value.trim() : value
    };

    const errorsToSet = {};
    Object.keys(inputs).forEach(input => {
      const validState = validator({
        inputObject: { name: input, value: updatedObject[input] },
        formName,
        inputRules,
        inputs: updatedObject
      });
      if (validState.errorMessage) {
        errorsToSet[input] = validState;
        // setError({ ...formErrors, [input]: validState });
      } else {
        const newErrors = formErrors;
        delete newErrors[input];
        setError({ ...newErrors });
      }
    });
    setError({ ...formErrors, ...errorsToSet });
    if (
      Object.entries(formErrors).length === 0 &&
      valuesFulfilled(formName, updatedObject, inputRules).fulfilled &&
      Object.keys(
        validator({ inputObject, formName, inputRules, inputs: updatedObject })
      ).length < 1
    ) {
      changeDisabled(false);
    } else {
      changeDisabled(true);
    }
    setUnfullfilledValues(
      valuesFulfilled(formName, updatedObject, inputRules).unfullfilledFields
    );
    return;
  };

  const handleInputChange = event => {
    if (event.persist) {
      event.persist();
    }

    setInputs(inputs => ({
      ...inputs,
      [event.target.name]: event.target.value
    }));
    resetFields(event.target.name, event.target.value);
  };

  const handleBlur = event => {
    if (event.persist) {
      event.persist();
    }
    setMeta({
      ...meta,
      [event.target.name]: { ...meta[event.target.name], blurred: true }
    });
    changeBlurElementName(event.target.name);
    setInputs(inputs => ({
      ...inputs,
      [event.target.name]:
        typeof event.target.value === "string"
          ? event.target.value.trim()
          : event.target.value
    }));
    if (validator) {
      const inputObject = {
        name: event.target.name,
        value:
          typeof event.target.value === "string"
            ? event.target.value.trim()
            : event.target.value
      };
      const validState = validator({
        inputObject,
        formName,
        inputRules,
        inputs
      });
      if (validState.errorMessage) {
        setError({ ...formErrors, [event.target.name]: validState });
      } else {
        const newErrors = formErrors;
        delete newErrors[event.target.name];
        setError({ ...newErrors });
      }

      if (
        Object.entries(formErrors).length === 0 &&
        valuesFulfilled(formName, inputs, inputRules).fulfilled &&
        !validState.errorMessage
      ) {
        changeDisabled(false);
      } else {
      }
    }
    setUnfullfilledValues(
      valuesFulfilled(formName, inputs, inputRules).unfullfilledFields
    );
  };

  const handleFocus = event => {
    if (event.persist) {
      event.persist();
    }
    setMeta({
      ...meta,
      [event.target.name]: { ...meta[event.target.name], touched: true }
    });
    changeFocusElementName(event.target.name);
  };

  const clearForm = initialState => {
    setInputs(initialState);
  };

  const checkIfInputIsValid = (input, inputValue) => {
    const validState = validator({
      inputObject: {
        name: input,
        value: inputValue
          ? inputValue
          : typeof inputs[input] === "string"
          ? inputs[input].trim()
          : inputs[input]
      },
      formName,
      inputRules,
      inputs
    });
    return validState;
  };

  const clearField = input => {
    setInputs(inputs => ({
      ...inputs,
      [input]: ""
    }));
    resetFields(input, "");
  };

  const checkIfFormIsValid = () => {
    const validState = validator({
      inputObject: {},
      formName,
      inputRules,
      inputs
    });
    if (
      Object.entries(formErrors).length === 0 &&
      valuesFulfilled(formName, inputs, inputRules).fulfilled &&
      !validState.errorMessage
    ) {
      changeDisabled(false);
    }
  };

  return {
    handleSubmit,
    handleInputChange,
    handleBlur,
    handleFocus,
    inputs,
    formErrors,
    disabled,
    clearForm,
    checkIfInputIsValid,
    clearField,
    unfullfilledValues,
    meta,
    checkIfFormIsValid,
    forceSubmit,
    inputActions: {
      blurElementName,
      changeBlurElementName
    }
  };
};

export { useFormHook };
