import { isPast, isToday, isDate, differenceInDays } from "date-fns";
import { CARD_NUMBER_REGEX, CARD_PIN_REGEX, PASSREGEX } from "@constants/regex";
const config = {
  newBuyForm: {
    amount: {
      isRequired: { message: "Please enter a amount." },
      regex: {
        pattern: /^[+-]?[0-9]{1,3}(:?[0-9]{3})*(?:.[0-9]{2})?$/,
        message: "Invalid Amount"
      }
    },
    date: {
      isRequired: { message: "Please select a valid date" },
      checkFunction: {
        func: e => {
          const date = new Date(e);
          const returnValue =
            isDate(date) &&
            (!isPast(date) || isToday(date)) &&
            differenceInDays(e, new Date()) < 90;
          return returnValue;
        },
        message: "Please select a valid date"
      }
    },
    fromEmail: {
      isRequired: { message: "Invalid Email" },
      regex: {
        pattern:
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: "Invalid Email"
      }
    },
    fromName: {
      isRequired: { message: "Invalid Name" },
      regex: {
        pattern:
          /^[A-z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.\\'-]+$/,
        message: "Invalid Name"
      }
    },
    toName: {
      isRequired: { message: "Invalid Name" },
      regex: {
        pattern:
          /^[A-z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.\\'-]+$/,
        message: "Invalid Name"
      }
    },
    toEmail: {
      isRequired: { message: "Invalid Email" },
      regex: {
        pattern:
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: "Invalid Email"
      }
    },
    message: { isRequired: { message: "Please enter a message" } }
  },
  signInPopup: {
    email: {
      isRequired: { message: "Invalid Email" },
      regex: {
        pattern:
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: "Invalid Email"
      }
    },
    password: {
      isRequired: { message: "Password required" }
    }
  },
  signUpPage: {
    firstName: {
      isRequired: { message: "Invalid Name" },
      regex: {
        pattern:
          /^[A-z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.\\'-]+$/,
        message: "Invalid Name"
      }
    },
    lastName: {
      isRequired: { message: "Invalid Name" },
      regex: {
        pattern:
          /^[A-z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.\\'-]+$/,
        message: "Invalid Name"
      }
    },
    email: {
      isRequired: { message: "Invalid Email" },
      regex: {
        pattern:
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: "Invalid Email."
      }
    },
    password: {
      isRequired: { message: "Password required" },
      regex: {
        pattern: PASSREGEX,
        message: "Invalid Password"
      }
    },
    confirmPassword: {
      isRequired: { message: "Password required" },
      regex: {
        pattern: PASSREGEX,
        message: "Invalid Password"
      }
    }
  },
  referForm: {
    fromEmail: {
      isRequired: { message: "Invalid Email" },
      regex: {
        pattern:
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: "Invalid Email"
      }
    },
    toEmail: {
      isRequired: { message: "Invalid Email" },
      regex: {
        pattern:
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: "Invalid Email"
      }
    },
    fromName: {
      isRequired: { message: "Invalid Name" },
      regex: {
        pattern:
          /^[A-z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.\\'-]+$/,
        message: "Invalid Name"
      }
    },
    message: { isRequired: { message: "Please enter a message" } }
  },
  sellForm: {
    number: {
      regex: {
        pattern: CARD_NUMBER_REGEX,
        message: "Invalid Card Number",
        onlyIfValue: true
      }
    },
    pin: {
      regex: {
        pattern: CARD_PIN_REGEX,
        message: "Invalid Card Number",
        onlyIfValue: true
      }
    }
  }
};

const validateSingleItem = ({ formName, inputObject, inputRules, inputs }) => {
  const errors = {};
  const form = config[formName];
  if (form[inputObject.name] && form[inputObject.name].isRequired) {
    if (!inputObject.value) {
      errors.errorMessage = form[inputObject.name].isRequired.message;
    }
  }
  if (
    !errors.errorMessage &&
    form[inputObject.name] &&
    form[inputObject.name].checkFunction
  ) {
    const testInput = form[inputObject.name].checkFunction.func(
      inputObject.value
    );
    if (!testInput) {
      errors.errorMessage = form[inputObject.name].checkFunction.message;
    }
  }

  if (
    !errors.errorMessage &&
    form[inputObject.name] &&
    form[inputObject.name].regex &&
    (!form[inputObject.name].regex.onlyIfValue ||
      (form[inputObject.name].regex.onlyIfValue && inputObject.value))
  ) {
    const regexPass = form[inputObject.name].regex.pattern.test(
      inputObject.value
    );
    if (!regexPass) {
      if (inputObject.value.includes(">")) {
        const splitValue = inputObject.value.split(">");
        splitValue.forEach(element => {
          const checkRegex = form[inputObject.name].regex.pattern.test(element);
          if (!checkRegex) {
            errors.errorMessage = form[inputObject.name].regex.message;
          }
        });
      } else {
        errors.errorMessage = form[inputObject.name].regex.message;
      }
    }
  }
  if (inputRules) {
    if (inputRules[inputObject.name]) {
      const inputPass = inputRules[inputObject.name](inputs);
      if (inputPass) {
        errors.errorMessage = inputPass;
      }
    }
  }
  return errors;
};

const valuesFulfilled = (formName, inputs, inputRules) => {
  const form = config[formName];
  let fulfilled = true;
  let unfullfilledFields = [];
  for (const field in form) {
    if (form[field].isRequired) {
      if (!inputs[field]) {
        fulfilled = false;
        unfullfilledFields.push(field);
      }
    }
    if (inputRules && inputRules[field]) {
      if (inputRules[field](inputs)) {
        fulfilled = false;
        unfullfilledFields.push(field);
      }
    }
  }

  return { fulfilled, unfullfilledFields };
};

export { valuesFulfilled, validateSingleItem };
